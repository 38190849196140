.Footer{
    background-color: #222;
    height: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
    font-size: 0.9em;
}

.Footer img{
    width: 20px;
    height: 20px;
}

.Footer a{
    color: lightgray;
}

.beianhao{
    margin-left: 30px;
}