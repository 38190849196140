.wrapper{
    display: flex;
    align-items: center;
    background-color: #1e73be;
    height: 40px;
    min-width: 110px;
    color: white;
    border: 1px solid #165389;
    border-radius: 5px;
    padding: 5px;
}

.wrapper p{
    margin-left: 10px;
}

.wrapper:hover{
    background-color:forestgreen;
}