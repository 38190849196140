.hbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.vbox{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.scaledImg {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.content{
    padding-top: 80px;
}