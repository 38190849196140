.Title{
  position: fixed;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(62, 63, 133, 0.95);
  height: 65px;
  z-index: 10;
}

.TitleLeft{
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 150px;
}
.TitleLeft>p{
  color: white;
  font-weight: bold;
  font-size: larger;
}

.TitleRight{
  display: flex;
  justify-content: space-around;
  flex-basis: 250px;
}

.TitleRight>a{
  color: white;
  font-size: large;
  text-decoration:none;
}