
.firstlook{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.swiper-container {
    margin-left: 0;
    margin-right: 0;
    width: 50%;
}

.swiper-img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.describe>p{
    margin-top: 2em;
    margin-bottom: 1.5em;
}

.describe{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 10px;
    width: 400px;
}

.describe>h1,h3{
    text-align: center;
}
.describe>p{
    color: gray;
}

.buyit{
    width: 300px;
}

a{
    text-decoration: none;
}