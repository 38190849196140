.vertical-timeline.vertical-timeline-custom-line::before {
  background: #424242;
}

.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--blue .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #1976d2, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  
.vertical-timeline.vertical-timeline-custom-line .vertical-timeline-element--red .vertical-timeline-element-icon {
    box-shadow: 0 0 0 4px #c2185b, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
  }
  
.vertical-timeline-element--blue .vertical-timeline-element-content {
    border-top: 3px solid #2196f3;
}

.vertical-timeline-element--red .vertical-timeline-element-content {
    border-top: 3px solid #e91e63;
}


.vertical-timeline-element-date {
    color: #333;
  }

.TimeLine {
    background-color: #f0f0f0;
}